@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Ramabhadra&display=swap');

.sections {
  background-color: #f5f4f0;
}

.section1-left p {
  font-weight: 300;
  font-size: xx-large;
}

.section1 {
  background-color: #f5f4f0;
  margin-top: 100px;
  color: #102c57;
}

.section1 img {
  width: 80%;
}

.section1-left h3 {
  font-size: 30.1px;
  font-weight: 500;
}

.section2 {
  background-color: #102c57;
  color: white;
}

.section2-left {
  height: 250px;
  width: 80%;
}

.section2-left:hover {
  background-color: white;
  color: #102c57;
}

.section2-right:hover {
  background-color: white;
  color: #102c57;
}

.section2-right {
  height: 250px;
  width: 80%;
}

.sec2img-div img {
  width: 80%;
}

.sec2img-div {
  background-color: #102c57;
  display: inline-block;

  border-radius: 9px;
  padding: 15px 0px 10px 10px;
}

.section3 {
  background-color: #f5f4f0;
  color: #102c57;
}

.sec3-div {
  width: 100%;
  font-size: x-large;
}

.section3 img {
  width: 80%;
}
.sec3-border {
  border-right: 4px solid #102c57;
}

.section3 button {
  color: white;
  background-color: #102c57;
}

.section3 button:hover {
  padding: 15px 25px;
  font-size: 18px;
  transform: scale(1.1);
}

.section4 {
  color: white;
  background-color: #102c57;
}

.bg-img {
  background-image: url("../../Images/sec5bgimg.svg");

  position: relative;
  height: 323px;
  width: 100%;
}

.section5 .card {
  background-color: #f5f4f0;
  margin: 0px 10px 0px 10px;
  width: 312px;
}

.section5 .card img {
  width: 59.55px;
  height: 53.77px;
  margin: 20px;
}

.section5 .card h3 {
  font-size: 20px;
  margin-left: 10px;
}

.section5 .card p {
  font-size: 18px;
  margin-left: 10px;
}

.sec5-cards {
  position: absolute;
  top: 40%;

  width: 100%;
}

.sec5-heading {
  color: white;
  padding-top: 40px;
}

.section6 {
  background-color: #f5f4f0;
}

.section6 h3 {
  color: #102c57;
}

.section7 {
  background-color: #102c57;
  color: white;
}

.section8 {
  background-color: #f5f4f0;
}

.sec8-card {
  width: 90%;
  height: 100%;
}

.section9 {
  background-color: #102c57;
}

.section9 h3 {
  color: white;
}

.sec9-right {
  margin-top: 30px;
  position: relative;
}

.sec9-card {
  position: absolute;
  top: 35%;
}

.sec9-card h3 {
  color: #102c57;
}
.sec9-card1 {
  margin-right: 30px;
  margin-left: 30px;
}

.section9 img {
  width: 90%;
}

.sec9-bottom {
  background-color: #f5f4f0;
}

.section10 {
  background-color: #102c57;
  color: white;
}

.section10 img {
  width: 90%;
}

.sec10-left {
  position: relative;
}
.sec10-card {
  position: absolute;
  margin: 20px;
  width: 100%;
}

.sec10-card1 {
  margin-right: 30px;
  margin-left: 30px;
}
.section11 {
  background-color: #f5f4f0;
}

.section11 img {
  width: 90%;
}

.sec11img-div {
  width: 70px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9%;
  padding: 10px;
  margin-bottom: 10px;
}

.sec11-card {
  height: 90%;
}

.sec11-card:hover {
  background-color: #102c57;
  color: white;
}
.sec11-bottom {
  background-color: #102c57;
  display: inline-block;
  border-radius: 50%;
  padding: 10px 10px 5px 10px;
  height: 60px;
  width: 60px;
}

.sec11-bottom img {
  width: 75%;
}

.section12 {
  background-color: #f5f4f0;
  color: white;
}

.sec12-main {
  background-color: #102c57;
}
.sec12-bottom {
  background-color: #f5f4f0;
}



.sec12-left {
 font-family:Baskervville;
}
.footer {
  background-color: #102c57;
  color: white;
}

.section12 button {
  background-color: white;
  margin-top: 20px;
}

.section12 button:hover {
  padding: 15px 25px;
  font-size: 18px;
  transform: scale(1.1);
}
.footer a {
  color: white;
  text-decoration: none;
}

.footer-mid {
  height: 2px;
  background-color: white;
}

.sec5-card1 {
  margin: 0px;
  padding: 0px;
}
@media (max-width: 768px) {
  .section1-left h3 {
    font-size: 30.1px;
    font-weight: 500;
    line-height: 40.53px;
  }

  .section1-left p {
    font-size: x-large;
  }
  .section5 .card {
    width: 90%;
  }

  .sec6-img {
    width: 100%;
  }

  .sec10-left {
    position: initial;
  }
  .sec10-card {
    position: initial;
    width: 80%;
  }
  .sec10-card1 {
    margin: 0;
  }
  .sec9-card1 {
    margin: 0px;
  }
  .sec9-right {
    margin-top: 30px;
    position: initial;
  }

  .sec9-card {
    position: initial;
    margin: 0 20px 20px 20px;
  }
}
